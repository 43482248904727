.postdetail {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.postdetail__container {
  width: 96%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  margin: 50px 0px 50px 15px;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid black;
  box-shadow: 1px 1px 10px 1px #000;
}

.postdetail__details {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
  margin: 0;
}

.postdetail__details h1 {
  font-size: 5rem;
  text-transform: capitalize;
  margin: 0;
  font-weight: 900;
}
.postdetail__details h3 {
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 2px solid #131313;
}
.postdetail__details p {
  font-size: 1rem;
}
.postdetail__details h5 {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 100px;
}
.postdetail__details button {
  width: 200px;
  align-self: center;
  outline: none;
  background: #fff;
  border: 2px solid #000;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.postdetail__details button:hover {
  background: #2980b9;
  background: -webkit-linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  background: linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  transition: all 0.3s ease-in-out;
}

.postdetail__details .fas {
  color: red;
}

.postdetail__image {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 100%;
  margin: 0;
}

.postdetail__image img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  max-height: 500px;
  object-fit: fill;
  border-radius: 30px;
}
.postdetail__comment--post {
  width: 100%;
}

.postdetail__comment {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.postdetail__comment h2 {
  font-size: 2.5rem;
}
.postdetail__comment--card {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 5px #000;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
}
.postdetail__comment--card h3 {
  margin: 0;
  margin: 0 0 10px 30px;
  width: 80%;
  border-bottom: 2px solid #131313;
}

.postdetail__comment--card h4 {
  margin: 0;
  padding-left: 30px;
  padding-right: 100px;
}
.postdetail__comment--card button {
  width: 100px;
  position: absolute;
  right: 20px;
  top: 30px;
  outline: none;
  background-color: red;
  border: none;
  height: 35px;
  border-radius: 10px;
  margin: none;
  padding: none;
}
.postdetail__profile-pic {
  position: absolute;
  top: 30px;
  left: 15px;
}

@media screen and (max-width: 1200px) {
  .postdetail__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 89%;
  }
  .postdetail__details {
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .postdetail__image img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    max-height: 500px;
    object-fit: fill;
    border-radius: 30px;
  }
  .postdetail__details h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 570px) {
  .postdetail__image img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    max-height: 500px;
    object-fit: fill;
    border-radius: 30px;
  }
  .postdetail__container {
    width: 80%;
  }
}
