.posts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px 0px;
  margin: 0px 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../images/photo-1505118380757-91f5f5632de0.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.posts__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  width: 80%;
  justify-content: center;
  align-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 50px;
  padding: 0px 20px;
}

.posts__search {
  width: 340px;
  height: 100%;
  display: flex;
  align-self: flex-start;
  margin-bottom: 50px;
  margin-right: 10px;
  padding: 10px 100px 10px 10px;
}
.posts__search--container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #076585; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fff,
    #076585
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fff,
    #076585
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 30px;
  padding: 20px;
}
.posts__search--error {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
}
.posts__search--container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.posts__search--container h3 {
  font-size: 1.5rem;
  text-align: center;
  margin: 0px;
  padding: 10px;
  padding: none;
  background: #373b44;
  background: -webkit-linear-gradient(to right, #4286f4, #373b44);
  background: linear-gradient(to right, #4286f4, #373b44);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.posts__search--container input {
  width: 250px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: 10px;
}
.posts__search--container button {
  outline: none;
  border: 2px solid black;
  width: 150px;
  height: 30px;
  border-radius: 30px;
  background: #fff;
  position: relative;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  cursor: pointer;
}
.posts__search--container button:hover {
  color: #131313;
}
.posts__search--container button:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  transition: all 0.3s ease;
  border-radius: 30px;
}
.posts__search--container button:hover:after {
  width: 100%;
}
.posts__loading {
  color: red;
  font-size: 5rem;
  z-index: 3;
}

.posts__loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 99;
}
.posts_nopost {
  color: red;
  z-index: 99;
  position: absolute;
}

@media screen and (max-width: 980px) {
  .posts {
    flex-direction: column-reverse;
  }
  .posts__search {
    width: 300px;
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 0px;
  }
  .posts__search--container input {
    width: 250px;
  }
}
