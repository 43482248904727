.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 999;
  height: 80px;
  position: sticky;
  top: 0;
  background: #2980b9;
  background: -webkit-linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  background: linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
}

.navbar__container {
  display: flex;
  height: 80px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
  z-index: 1;
}

.navbar__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #373b44;
  background: -webkit-linear-gradient(to right, #4286f4, #373b44);
  background: linear-gradient(to right, #4286f4, #373b44);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 3rem;
  text-decoration: none;
  cursor: pointer;
}

.navbar__menu {
  display: flex;
  align-items: center;
  list-style: none;
}
.navbar__items {
  height: 80px;
}

.navbar__links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 125px;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
}
.navbar__links--user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  text-align: center;
  color: #fff;
}
.navbar__links--user span {
  background: #da4453; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #89216b,
    #da4453
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #89216b,
    #da4453
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.navbar__links:hover {
  color: #042457;
  transition: all 0.35s ease;
}

.navbar__menu button {
  outline: none;
  border: none;
  height: 40px;
  width: 125px;
  background: red;
  font-size: 1.5rem;
  margin-top: 20px;
  border-radius: 20px;
}

@media screen and (max-width: 960px) {
  .navbar__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    padding: 0;
  }

  .navbar__menu {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    position: absolute;
    top: -1000px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -1;
  }

  .navbar__menu.active {
    background: #2193b0;
    background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);
    background: linear-gradient(to right, #6dd5ed, #2193b0);
    z-index: 99;
    top: 100%;
    opacity: 1;
    font-size: 1.6rem;
    transition: all 0.5s ease;
    height: 60vh;
    margin-right: 30px;
  }

  .navbar__items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar__links {
    text-align: center;
    padding: 0;
    width: 100%;
    display: table;
  }

  .navbar__toggle .bar {
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background: #fff;
    display: block;
    cursor: pointer;
  }

  #mobile-menu {
    position: absolute;
    top: 20%;
    right: 5%;
    transform: translate(5%, 20%);
  }

  .navbar__toggle .bar {
    display: block;
    cursor: pointer;
  }

  #mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
  }
  #mobile-menu.is-active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  #mobile-menu.is-active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

@media screen and (max-width: 370px) {
  .navbar__logo {
    font-size: 1.5rem;
  }
}
