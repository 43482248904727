.post {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100%;
  padding: 0 0;
  margin: 0;
  transition: 0.4s ease;
  border-radius: 30px;
}

.post:hover {
  transform: scale(1.1);
  transition: 0.4s ease;
}

.post__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 500px;
  flex-direction: column;
  border-radius: 30px;
  background: #fff;
  position: relative;
}

.post__error--login {
  position: absolute;
  color: red;
  z-index: 10;
  bottom: 10px;
}

.post__img {
  position: relative;
  width: 100%;
  height: 100%;
}

.post__img img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  object-position: center;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
}
.post__img h2 {
  color: #fff;
  position: absolute;
  top: 25px;
  left: 20px;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.post__img p {
  color: #fff;
  position: absolute;
  top: 80px;
  left: 20px;
}

.post__img div {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 3rem;
  margin: 0;
  padding: 0;
  z-index: 99;
}

.post__img--editlink {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.post__content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.post__content p {
  opacity: 0.8;
  padding: 0 10px;
  margin: 0;
}

.post__content h2 {
  margin: 0;
  padding: 10px;
  font-weight: 900;
}

.post__content h4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  margin: 0px 0px 20px 0px;
  font-weight: 700;
  opacity: 1;
  max-height: 100px;
  width: 100%;
}

.post__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.post__buttons button {
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  color: red;
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
  background: #fff;
}

.post__buttons button:hover:after {
  width: 100%;
  transition: 0.35s ease;
}

.posts__click {
  text-decoration: none;
  color: #1f1f1f;
}
