.comment__container {
  align-self: flex-start;
  width: 100%;
  height: 100%;
}

.comment__form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.comment__form h2 {
  font-size: 2.5rem;
  padding: 0;
  margin-bottom: 10px;
}

.comment__form textarea {
  width: 500px;
  height: 100px;
  max-width: 500px;
  max-height: 100px;
  min-width: 500px;
  min-height: 100px;
  margin-bottom: 20px;
}

.comment__form button {
  width: 50%;
  height: 40px;
  border: 2px solid black;
  outline: none;
  border-radius: 30px;
  transition: 0.3s ease;
  background-color: #fff;
}

.comment__form p {
  position: absolute;
  color: red;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.comment__form button:hover {
  transition: 0.3s ease;
  background: rgba(18, 183, 224, 0.315);
}

@media screen and (max-width: 600px) {
  .comment__form textarea {
    max-width: 300px;
    width: 300px;
    min-width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .comment__form textarea {
    max-width: 200px;
    width: 200px;
    min-width: 200px;
  }
}
