body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
