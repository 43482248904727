.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2980b9;
  background: -webkit-linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  background: linear-gradient(to right, #ffffff, #6dd5fa, #2980b9);
  height: 80px;
  padding: 80px 10px;
  position: relative;
  bottom: 100%;
}

.footer__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 80px;
}

.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #373b44;
  background: -webkit-linear-gradient(to right, #4286f4, #373b44);
  background: linear-gradient(to right, #4286f4, #373b44);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
}

.footer__container p {
  background: #373b44;
  background: -webkit-linear-gradient(to right, #4286f4, #373b44);
  background: linear-gradient(to right, #4286f4, #373b44);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.footer__icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
}

@media screen and (max-width: 550px) {
  .footer__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
